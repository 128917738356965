
import { Vue, Component, Ref } from "vue-property-decorator";
import { InvitationListDataSource } from "@/data/List/InvitationListDataSource";
import DataList from "@/components/common/data/list.vue";
import userModule from "@/store/modules/user";
import { emailAddres, instaceStates, required } from "@/cms-services/consts";
import { ListDataSource } from "@/data/List/ListDataSource";
@Component({
  components: {
    DataList,
  },
})
export default class Home extends Vue {
  @Ref("dataList") dataList!: DataList;
  $message: any;
  countryIdCache: any;
  userModule = userModule;
  dataSource: InvitationListDataSource = new InvitationListDataSource({
    pageIndex: 1,
    pageSize: 100,
  });

  createItemModel: any = {
    userProfileId: +userModule.profile.id,
    templatePath: "Mail/Invite",
    subject: "Приглашение в систему",
    professionalArea:"",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    countryId: null,
    cityId: null,
  }

  async download() {
    await this.dataSource.downloadXlsx();
  }

  createItemFields: any = [
    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Фамилия",
      },
      name: "lastName",
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Имя",
      },
      name: "firstName",
      validations: [required()],
    },

    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Отчество",
      },
      name: "patronymic",
    },
    {
      editor: "string",
      attrs: {
        label: "Email",
        placeholder: "Введите Email",
        type: "email",
      },
      name: "email",
      validations: [required(), emailAddres()],
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Мобильный телефон",
        mask: "+7(###) ###-##-##",
      },
      name: "mobilePhone",
      validations: [required()],
    },
    {
      editor: "auto-complete-select",
      attrs: {
        label: "Страна",
      },
      config: {
        getItems: "getCountries",
        labelKeyName: "name",
        valueKeyName: "id",
      },
      grid: { cols: 12, md: 6 },
      name: "countryId",
      validations: [required()],
    },
    {
      editor: "auto-complete-select",
      attrs: {
        label: "Город",
        hint: "Чтобы выбрать город выберите страну",
        persistentHint: true,
      },
      config: {
        getItems: "getCities",
        labelKeyName: "name",
        valueKeyName: "id",
      },
      grid: { cols: 12, md: 6 },
      name: "cityId",
      watchers: [
        {
          changeModel: "watchCountry",
          changeItems: "filterCities",
          changeAttrs: "activateOrDisactivateCity",
        },
      ],
      validations: [required()],
    },
    {
      editor: "string",
      attrs: {
        type: "text",
        label: "Специализация",
      },
      name: "professionalArea",
      validations: [required()],
    }
  ];

  countryDataSource: ListDataSource = new ListDataSource({
    className: "region",
    config: {
      pageSize: 1000,
      orderFieldName: "Priority",
      orderFieldDirection: "Asc",
      filter: JSON.stringify([
        {
          fieldName: "regionTypeId",
          fieldValue: 1,
        },
      ]),
    },
  });
  cityDataSource: ListDataSource = new ListDataSource({
    className: "region",
    config: {
      pageSize: 10000,
      orderFieldName: "Priority",
      orderFieldDirection: "Asc",
    },
  });
  createItemActions: any = {
    async watchCountry(model) {
      if (!!model.countryId) return;
      model.cityId = null;
    },
    filterCities: async (model: any) => {
      if (!model.countryId) return [];
      if (model.countryId == this.countryIdCache)
        return this.cityDataSource.items;

      this.countryIdCache = model.countryId;
      this.cityDataSource.config.filter = JSON.stringify([
        {
          fieldName: "parentId",
          fieldValue: model.countryId,
        },
      ]);
      await this.cityDataSource.get();
      return this.cityDataSource.items;
    },
    getCities: () => this.cityDataSource.items,
    getCountries: () => this.countryDataSource.items,
    activateOrDisactivateCity: (model: any) => {
      return !!model.countryId
        ? {
            label: "Город *",
          }
        : {
            label: "Город *",
            disabled: true,
            hint: "Чтобы выбрать город выберите страну",
            persistentHint: true,
          };
    },
  };

  getInviteCaption(item) {
    switch (item.instanceStateId) {
      case 200:
        return "Пригласить повторно";
      case 1:
        return "Пригласить";
    }
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Приглашения",
        disabled: true,
      },
    ];
  }

  copyLink(invitation: any) {
    navigator.clipboard.writeText(
      `https://ds-world.ru/login/register?inviteGuid=${invitation.guid}`,
    );
    this.$message(`Ссылка на приглашение успешно скопирована в буфер обмена`);
  }

  getInstanceStateColor(instanceStateId): string {
    switch (instanceStateId) {
      case 200:
        return "info";
      case 1:
        return "warning";
      case 11:
        return "success";
      default:
        return "default";
    }
  }

  async invite(item) {
    this.dataList.loading = true;
    await this.dataSource.invite(item.id);
    this.dataList.loading = false;
    item.instanceStateId = 200;
    this.$message(`Приглашение успешно отправлено на почту ${item.email}`);
  }

  async setInstanceState(item, event) {
    await this.dataSource.updateField(item.id, [
      {
        fieldName: "instanceStateId",
        fieldValue: event,
      },
    ]);
  }
  async created() {
    await this.countryDataSource.get();
  }
}
